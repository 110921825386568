<template>
  <div>
    <iframe src="https://cdc.ennovagroup.it/demo_cdc/home.php" style="border: 0;" class="full-height" />
  </div>
</template>

<script>

export default {
  name: 'CDC',
  page: {
    title: 'Analisi centro di costo',
  },
  components: {  },
  data: () => ({
  }),
  methods: {},
}
</script>
